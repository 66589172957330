import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {NavBarComponent} from './components/shared/nav-bar/nav-bar.component';
import {UsersComponent} from './components/users/users.component';
import {PersonRegistryComponent} from './components/person-registry/person-registry.component';
import {CreatePersonComponent} from './components/create-person/create-person.component';
import {EditPersonComponent} from './components/edit-person/edit-person.component';
import {CreateUserComponent} from './components/create-user/create-user.component';
import {EditUserComponent} from './components/edit-user/edit-user.component';
import {SectorsComponent} from './components/sectors/sectors.component';
import {SquaresComponent} from './components/squares/squares.component';
import {FieldsComponent} from './components/fields/fields.component';
import {SettingsComponent} from './components/settings/settings.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {AuditsComponent} from './components/audits/audits.component';
import {ScheduleComponent} from './components/schedule/schedule.component';
import {HistoryComponent} from './components/history/history.component';
import {HistorySectorComponent} from "./components/history-sector/history-sector.component";
import {CropsComponent} from './components/crops/crops.component';
import {LogsComponent} from './components/logs/logs.component';
import {SingleFieldComponent} from './components/fields/single-field/single-field.component';
import {CropSquaresComponent} from './components/crops/crop-squares/crop-squares.component';
import {CropFieldsComponent} from './components/crops/crop-fields/crop-fields.component';
import {CropSingleFieldComponent} from './components/crops/crop-single-field/crop-single-field.component';
import {HelpComponent} from './components/help/help.component';
import {HistorySquareComponent} from "./components/history-square/history-square.component";
import {HistoryFieldComponent} from "./components/history-field/history-field.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    component: LoginPageComponent,
    path: 'login',
  },
  {
    component: NavBarComponent,
    path: 'start',
    children: [
      {
        component: HomePageComponent,
        path: 'home',
      },
      {
        component: UsersComponent,
        path: 'users',
      },
      {
        component: PersonRegistryComponent,
        path: 'registry',
      },
      {
        component: SectorsComponent,
        path: 'sectors',
      },
      {
        component: CreatePersonComponent,
        path: 'create-person',
      },
      {
        component: EditPersonComponent,
        path: 'edit-person/:id',
      },
      {
        component: CreateUserComponent,
        path: 'create-user',
      },
      {
        component: EditUserComponent,
        path: 'edit-user/:id',
      },
      {
        component: SquaresComponent,
        path: 'squares/:sector_id/:sector_name',
      },
      {
        component: CropSquaresComponent,
        path: 'crop-squares/:sector_id/:sector_name',
      },
      {
        component: FieldsComponent,
        path: 'fields/:square_id/:square_name',
      },
      {
        component: CropFieldsComponent,
        path: 'crop-fields/:square_id/:square_name',
      },
      {
        component: SingleFieldComponent,
        path: 'field/:id',
      },
      {
        component: CropSingleFieldComponent,
        path: 'crop-single-field/:id',
      },
      {
        component: SettingsComponent,
        path: 'settings',
      },
      {
        component: NotificationsComponent,
        path: 'notifications',
      },
      {
        component: AuditsComponent,
        path: 'audits',
      },
      {
        component: ScheduleComponent,
        path: 'schedule',
      },
      {
        component: HistoryComponent,
        path: 'history',
      },
      {
        component : HistorySectorComponent,
        path: 'history-sector/:sector_id/:sector_name'
      },
      {
        component : HistorySquareComponent,
        path: 'history-square/:square_id/:square_name'
      },
      {
        component : HistoryFieldComponent,
        path: 'history-field/:field_id/:field_name'
      },
      {
        component: CropsComponent,
        path: 'crops',
      },
      {
        component: LogsComponent,
        path: 'logs',
      },
      {
        component: HelpComponent,
        path: 'help',
      },
    ],
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
