import {Injectable} from '@angular/core';
import {PersonService} from './person/person.service';
import {UserService} from './user/user.service';
import {SectorService} from './sectors/sector.service';
import {SquareService} from './squares/square.service';
import {LogService} from './log.service';
import {FieldService} from './fields/field.service';
import {NotificationsService} from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(private personService: PersonService,
              private sectorService: SectorService,
              private squareService: SquareService,
              private logService: LogService,
              private notificationsService: NotificationsService,
              private fieldService: FieldService,
              private userService: UserService) {
  }

  async fetchDataFromModel(model: string, param?: number, date?: string){
    let data = [];
    const tempModel = model.split('-');
    const m = tempModel[0];
    switch (m) {
      case 'person':
        data = await this.personService.getPeople();
        break;
      case 'users':
        data = await this.userService.getUsers();
        break;
      case 'history/sectorid':
        data = [await  this.sectorService.fetchSectorHistory(param,date)];
        break;
      case 'history/web/fieldid':
        data = await this.fieldService.fetchFieldHistory(param);
        break;
      case 'history/squareid':
        data = [await this.squareService.fetchSquareHistory(param,date)];
        break;
      case 'sectors':
        data = await this.sectorService.getSectors();
        data.forEach(record => record.isActive = record.is_active === 1 ? 'NAI' : 'ΟΧΙ');
        break;
      case 'squares':
        data = await this.squareService.getSectorSquares(param);
        break;
      case 'notifications':
        data = await this.notificationsService.fetchAll();
        break;
        case 'pendingNotifications':
        data = await this.notificationsService.fetchPending();
        break;
      case 'fields':
        if (model === 'fields-all') {
          data = await this.fieldService.getSquareFields(param);
        }
        if (model === 'fields-active') {
          data = await this.fieldService.getActiveSquareFields(param);
        }
        if (model === 'fields-inActive') {
          data = await this.fieldService.getInActiveSquareFields(param);
        }
        if (model === 'fields-inFertile') {
          data = await this.fieldService.getInFertileSquareFields(param);
        }

        data.forEach(record => {
          if (model === 'fields-inFertile') {
            record.displayName = `${record.name} - ${record.community_name}`;
          } else {
            record.displayName = `${record.name} - ${record.community_name} (${record.position})`;
          }
          record.isIrrigated = record.is_irrigated === 1 ? 'NAI' : 'OXI';
        });
        break;
      case 'logs':
        data = await this.logService.fetchLogs();
        break;
    }

    return data;
  }
}
