<div mat-dialog-title fxLayout='row' style="justify-content: space-between;
  align-items: flex-start;margin-bottom: 0;">
  <h2 mat-dialog-title>{{title}}</h2>
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div>
    <p>Οι επιλεγμένοι χρήστες θα έχουν απενεργοποιημένη την ενέργεια.</p>
  </div>
  <div>

    <div style="display: flex; align-items: center;" fxLayout="row">
      <mat-checkbox style="display: flex; flex: 0.5; justify-content: flex-start;" color="primary" [(ngModel)]="selectAll" (click)="selectAllUsers()">Επιλογή όλων</mat-checkbox>
      <mat-label style="display: flex; flex: 0.5; justify-content: center;">Ρόλος</mat-label>
    </div>

    <div *ngFor="let user of users">
      <div fxLayout="row" style="display: flex; align-items: center">
        <mat-checkbox color="primary" style="display: flex; flex: 0.5; justify-content: flex-start" [(ngModel)]="user.is_disabled">{{ user.name }}</mat-checkbox>

        <div style="display: flex; flex: 0.5; justify-content: center;">
          <ng-container *ngFor="let role of user.role; let last = last">
            <mat-label>{{ role.name }}</mat-label>
            <mat-label *ngIf="!last">,</mat-label>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-raised-button (click)="saveConfiguration()">
    Αποθήκευση
  </button>
</div>


