<div fxLayout="column" fxFlex="100" fxLayoutAlign="center;" style="width: 800px;">
  <h1 style="text-align: center;">Επαναποστολή της ειδοποίησης</h1>
  <div fxLayout="row wrap" fxFlex="100" style="margin-top: 3rem;" fxLayoutAlign="center">
    <div fxLayout="column" fxFlex="49" fxLayoutAlign="center">
      <h3 style="text-align: center;">Επιλέξτε τους χρήστες που θα αποσταλεί η ειδοποίηση</h3>
      <mat-form-field appearance="outline" style="overflow-x:hidden;">
        <mat-select [(ngModel)]="selectedUsers" multiple>
          <input [(ngModel)]="searchTxt" matInput placeholder="Αναζήτηση με επώνυμο" style="width: 80%;padding: 1rem;">
          <mat-option *ngFor="let user of users | search : searchTxt"
                      [value]="user">{{user.firstname}} {{user.lastname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxFlex="100" style="margin-top: 1rem;" fxLayoutAlign="center">
    <div fxLayout="row" fxFlex="49" fxLayoutAlign="center">
      <button mat-raised-button (click)="submit()">Καταχώρηση</button>
    </div>
  </div>
</div>
