import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private httpClient: HttpClient) {
    }

    uploadFile(url, data, options) {
        return new Promise((resolve, reject) => {
            this.httpClient.post(url, data, options).subscribe(response => resolve(data), error => reject(error));
        });
    }

    getStatus(record) {
        if (record.hasActiveIrrigation === 1 && record.is_paused === 0) {
            return 'Αρδέυεται';
        } else if (record.hasActiveIrrigation === 1 && record.is_paused === 1) {
            return 'Σε παύση';
        }
    }


    public exportAsCustomExcelFile(data: any, excelFileName: string, columns: any[], custom?: boolean): void {
        const dataToExport = [];
        if (!custom) {
            data.forEach(record => {
                const model = {};

                for (const column of columns) {
                    model[column.label] = record[column.key];
                }
                dataToExport.push(model);
            });
        } else {
            data.forEach(record => {
                const model = {};

                for (const [i, column] of columns.entries()) {
                    const keys = Object.keys(record);
                    model[column.label] = keys[i];
                }
                dataToExport.push(model);
            });
        }

        const columnsNumber = columns.length;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
        FileSaver.saveAs(data, fileName + '-' + new Date().getTime() + EXCEL_EXTENSION);
    }

    formatUserExcelModel(filteredData) {
        const data = [];
        for (const record of filteredData) {
            const model = {
                id: record.id,
                first_name: record.firstname,
                last_name: record.lastname,
                username: record.username,
                roles: record.displayRoles,
                status: record.is_active === 1 ? 'Ενεργός' : 'Ανενεργός'
            };
            data.push(model);
        }

        const columns = [
            {
                key: 'id',
                label: 'Αναγνωριστικό',
            },
            {
                key: 'first_name',
                label: 'Όνομα',
            },
            {
                key: 'last_name',
                label: 'Επώνυμο',
            },
            {
                key: 'username',
                label: 'Όνομα χρήστη',
            },
            {
                key: 'roles',
                label: 'Ρόλοι',
            },
            {
                key: 'status',
                label: 'Κατάσταση',
            }
        ];

        return {data, columns};
    }

    formatRegistryExcelModel(filteredData) {
        const data = [];
        for (const record of filteredData) {
            const model = {
                id: record.toeb_id,
                first_name: record.firstname,
                last_name: record.lastname,
                midname: record.midname,
                vat_number: record.vat_number,
                mobile_number: record.mobile_number,
                phone_number: record.phone_number
            };
            data.push(model);
        }

        const columns = [
            {
                key: 'id',
                label: 'Αναγνωριστικό',
            },
            {
                key: 'first_name',
                label: 'Όνομα',
            },
            {
                key: 'last_name',
                label: 'Επώνυμο',
            },
            {
                key: 'midname',
                label: 'Όνομα πατρός',
            },
            {
                key: 'vat_number',
                label: 'ΑΦΜ',
            },
            {
                key: 'mobile_number',
                label: 'Κινητό',
            },
            {
                key: 'phone_number',
                label: 'Τηλέφωνο',
            }
        ];

        return {data, columns};
    }

    formatSectorExcelModel(filteredData) {
        const data = [];
        for (const record of filteredData) {
            const model = {
                name: record.name,
                status: record.is_active === 1 ? 'Ενεργός' : 'Ανενεργός',
                max_open_supply: record.max_open_supply,
                open_supply: record.open_supply,
                planned_liters: record.planned_liters,
                max_supply: record.max_supply
            };
            data.push(model);
        }

        const columns = [
            {
                key: 'name',
                label: 'Όνομα τομέα',
            },
            {
                key: 'status',
                label: 'Κατάσταση',
            },
            {
                key: 'max_open_supply',
                label: 'Άρδευση',
            },
            {
                key: 'open_supply',
                label: 'Παροχή',
            },
            {
                key: 'planned_liters',
                label: 'Προγραμματισμός',
            },
            {
                key: 'max_supply',
                label: 'Μέγιστη παροχή',
            }
        ];
        return {data, columns};
    }

    formatSquaresExcelModel(filteredData) {
        const data = [];
        for (const record of filteredData) {
            const model = {
                name: record.name,
                status: record.is_active === 1 ? 'Ενεργό' : 'Ανενεργό',
                max_open_supply: record.max_open_supply,
                open_supply: record.open_supply,
                planned_liters: record.planned_liters
            };
            data.push(model);
        }

        const columns = [
            {
                key: 'name',
                label: 'Όνομα τετραγώνου',
            },
            {
                key: 'status',
                label: 'Κατάσταση',
            },
            {
                key: 'max_open_supply',
                label: 'Άρδευση',
            },
            {
                key: 'open_supply',
                label: 'Παροχή',
            },
            {
                key: 'planned_liters',
                label: 'Προγραμματισμός',
            }
        ];

        return {data, columns};
    }

    formatActiveIrrigationExcelModel(filteredData) {
        const data = [];
        for (const record of filteredData) {
            const model = {
                display_name: record.displayName,
                crop_name: record.crop_name,
                status: this.getStatus(record),
                acres: record.acres,
                irrigator_fullname: record.irrigator_fullname,
                start_time: record.start_time,
                estimated_duration: record.estimated_duration,
                estimated_end_time: record.estimated_end_time,
                method_name: record.method_name
            };
            data.push(model);
        }

        const columns = [
            {
                key: 'display_name',
                label: 'Όνομα',
            },
            {
                key: 'crop_name',
                label: 'Καλλειέργεια',
            },
            {
                key: 'status',
                label: 'Κατάσταση',
            },
            {
                key: 'acres',
                label: 'Έκταση',
            },
            {
                key: 'irrigator_fullname',
                label: 'Αρδευτής',
            },
            {
                key: 'start_time',
                label: 'Ώρα έναρξης',
            },
            {
                key: 'estimated_duration',
                label: 'Εκτιμώμενη διάρκεια',
            },
            {
                key: 'estimated_end_time',
                label: 'Εκτιμώμενη ώρα λήξης',
            },
            {
                key: 'method_name',
                label: 'Τρόπος άρδευσης',
            }
        ];

        return {data, columns};
    }

    formatFieldsIrrigationExcelModel(filteredData) {
        const data = [];
        for (const record of filteredData) {
            const model = {
                display_name: record.displayName,
                crop_name: record.crop_name,
                acres: record.acres,
                irrigator_fullname: record.irrigator_fullname
            };
            data.push(model);
        }

        const columns = [
            {
                key: 'display_name',
                label: 'Όνομα',
            },
            {
                key: 'crop_name',
                label: 'Καλλιέργεια',
            },
            {
                key: 'acres',
                label: 'Έκταση',
            },
            {
                key: 'irrigator_fullname',
                label: 'Αρδευτής',
            }
        ];

        return {data, columns};
    }
    formatHistorySector(filteredData) {
      const data = [];
      for ( const record of filteredData) {
        const model = {
          planned_liters: record.planned_liters,
          total_duration: record.total_duration,
          total_consumption: record.total_consumption,
          start_time: record.start_time,
          end_time: record.end_time
        }
        data.push(model);
      }
      const columns = [
    {
      key:'planned_liters',
      label:'Έγκριση',
    },
    {
      key: 'total_duration',
      label: 'Σύνολο ωρών'
    },
    {
      key: 'total_consumption',
      label: 'Συνολική κατανάλωση'
    },
    {
      key:'start_time',
      label: 'Ώρα έναρξης'
    },
    {
      key:'end_time',
      label:'Ώρα λήξης'
    }
    ];
      return {data,columns};
    }

  formatHistoryField(filteredData) {
    const data = [];
    for ( const record of filteredData) {
      const model = {
        method_name: record.method_name,
        start_time: record.start_time,
        end_time: record.end_time,
        duration: record.duration,
        consumption: record.consumption
      }
      data.push(model);
    }
    const columns = [
      {
        key:'method_name',
        label:'Τύπος',
      },
      {
        key:'start_time',
        label: 'Ώρα έναρξης'
      },
      {
        key:'end_time',
        label:'Ώρα λήξης'
      },
      {
        key: 'duration',
        label: 'Διάρκεια'
      },
      {
        key: 'consumption',
        label: 'Κατανάλωση'
      }
    ];
    return {data,columns};
  }
  formatHistorySquare(filteredData) {
    const data = [];
    for ( const record of filteredData) {
      const model = {
        name: record.name,
        planned_liters: record.planned_liters,
        total_duration: record.total_duration,
        total_consumption: record.total_consumption,
        start_time: record.start_time,
        end_time: record.end_time
      }
      data.push(model);
    }
    const columns = [
      {
        key: 'planned_liters',
        label: 'Έγκριση'
      },
      {
        key: 'total_duration',
        label: 'Συνολική Διάρκεια'
      },
      {
        key: 'total_consumption',
        label: 'Συνολική Κατανάλωση'
      },
      {
        key:'start_time',
        label: 'Ώρα έναρξης'
      },
      {
        key:'end_time',
        label:'Ώρα λήξης'
      },

    ];
    return {data,columns};
  }
}
