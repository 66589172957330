import {Component, OnInit} from '@angular/core';
import {AuditsService} from '../../../services/audits/audits.service';
import {ToastMessagesService} from "../../../services/toast-messages.service";
import * as moment from "moment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {
  data = [];
  selectedCategory: any;
  chartOptions = {
    xLabel: '',
    yLabel: '',
    legendTitle: ''
  };

  categories = [{
    id: 1,
    label: 'Αρδευόμενα αγροτεμάχια στον τομέα',
    value: 'irrigated_by_sector'
  },
    {
      id: 2,
      label: 'Βαθμός απόδοσης δικτύου',
      value: 'network_performance'
    },
    {
      id: 3,
      label: 'Στοιχεία απόδοσης τομέων',
      value: 'sector_performance'
    },
    {
      id: 4,
      label: 'Χρήση νερού ανά καλλιέργεια και στρέμμα',
      value: 'water_per_crop_per_acres'
    }];
  fromDate: any;
  toDate: any;

  constructor(private auditService: AuditsService, public toastMessagesService: ToastMessagesService) {
  }

  async ngOnInit(): Promise<void> {
    try {
      // this.data = await this.initializeStatistics();
      // console.log(this.data);
    } catch (e) {
      console.log(e);
    }
  }

  async initializeStatistics() {
    const result = [];
    this.data = [];
    let url = '';
    if(this.selectedCategory.id !== 3) {
      url = this.selectedCategory.value;
    } else {
      const filterFrom = moment(this.fromDate).format('YYYY-MM-DD');
      const filterTo = moment(this.toDate).format('YYYY-MM-DD');
      url = `${this.selectedCategory.value}&from=${filterFrom}&to=${filterTo}`;
    }

    const data = await this.auditService.fetchAll(url);
    data.forEach(record => {
      const model = {
        name: record.name,
        series: []
      };
      record.series.forEach(s => {
        model.series.push({name: s.name, value: s.value});
      });
      result.push(model);
    });

    this.data = result;
    // return result;
  }

  async categoryChanged() {

  }

  async search() {
    try {
      switch (this.selectedCategory.id) {
        case 1:
          this.chartOptions.xLabel = 'Πλήθος';
          this.chartOptions.yLabel = 'Τομείς';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
        case 2:
          this.chartOptions.xLabel = 'Απόδοση δικτύου (λίτρα)';
          this.chartOptions.yLabel = 'Τομείς';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
        case 3:
          this.chartOptions.xLabel = 'Βαθμός χρήσης νερού';
          this.chartOptions.yLabel = 'Τομείς';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
        case 4:
          this.chartOptions.xLabel = 'Χρήση νερού ανά στρέμμα';
          this.chartOptions.yLabel = 'Καλλιέργεια';
          this.chartOptions.legendTitle = 'Κατάσταση';
          break;
      }

      await this.initializeStatistics();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    }
  }
  exportAsCustomExcelFile(data: any, excelFileName: string, columns: any[], custom?: boolean): void {
    const dataToExport = [];
    if (!custom) {
      data.forEach(record => {
        const model = {};

        for (const column of columns) {
          model[column.label] = record[column.key];
        }
        dataToExport.push(model);
      });
    } else {
      data.forEach(record => {
        const model = {};

        for (const [i, column] of columns.entries()) {
          const keys = Object.keys(record);
          model[column.label] = keys[i];
        }
        dataToExport.push(model);
      });
    }

    const columnsNumber = columns.length;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '-' + new Date().getTime() + EXCEL_EXTENSION);
  }
  formatAuditsExcelModel(filteredData,id) {
    const data = [];
    let columns = [];
    for (const record of filteredData) {
      if(record.series.length>1){
        const model = {
          name: record.name,
          value: record.series[0].value,
          value2: record.series[1].value
        };
        data.push(model)
      }
      else{
        const model2 = {
          name: record.name,
          value: record.series[0].value
        };
        data.push(model2)
      }
    }
    if(id===1){
      columns = [
        {
          key: 'name',
          label: 'Τομέας',
        },
        {
          key: 'value',
          label: 'Αρδευόμενα (ποσοστό %)',
        },
        {
          key: 'value2',
          label: 'Μη Αρδευόμενα (ποσοστό %)',
        }
      ];
    }
    else if(id===2){
      columns = [
        {
          key: 'name',
          label: 'Τομέας',
        },
        {
          key: 'value',
          label: 'Απόδοση Δικτύου (λίτρα)',
        }
      ];
    }
    else if(id==3){
      columns = [
        {
          key: 'name',
          label: 'Τομέας',
        },
        {
          key: 'value',
          label: 'Βαθμός χρήσης νερού',
        }
      ];
    }
    else if(id===4){
      columns = [
        {
          key: 'name',
          label: 'Καλλιέργεια',
        },
        {
          key: 'value',
          label: 'Χρήση νερού ανά στρέμμα (κυβικά)',
        }
      ];
    }

    return {data, columns};
  }


  exportToExcel(id:number) {
    const excelData = this.formatAuditsExcelModel(this.data,id);
    console.log(excelData);
    this.exportAsCustomExcelFile(excelData.data,'audits',excelData.columns);
  }

  isButtonDisabled() {
    return this.data.length === 0;
  }
}
