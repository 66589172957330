import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @ViewChild('table', {static: true}) table: any;
  displayColumns = [{key:'name', label:'Ονομα τομέα'}];
  model='sectors';
  hasNavigation = true;
  navigateTo = 'start/history-sector';
  constructor() { }

  ngOnInit(): void {

  }

}
