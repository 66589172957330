import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InterceptInterceptor} from '../interceptors/intercept.interceptor';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {HomePageComponent} from './components/home-page/home-page.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NavBarComponent} from './components/shared/nav-bar/nav-bar.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {UsersComponent} from './components/users/users.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ConfirmOptionDialogComponent} from './components/shared/confirm-option-dialog/confirm-option-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {UserCreationDialogComponent} from './components/user-creation-dialog/user-creation-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MAT_DATE_LOCALE, MatOptionModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {PersonRegistryComponent} from './components/person-registry/person-registry.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {CreatePersonComponent} from './components/create-person/create-person.component';
import {EditPersonComponent} from './components/edit-person/edit-person.component';
import {PersonFormComponent} from './components/shared/person-form/person-form.component';
import {UserEditDialogComponent} from './components/user-edit-dialog/user-edit-dialog.component';
import {EditUserComponent} from './components/edit-user/edit-user.component';
import {CreateUserComponent} from './components/create-user/create-user.component';
import {SectorsComponent} from './components/sectors/sectors.component';
import {SquaresComponent} from './components/squares/squares.component';
import {FieldsComponent} from './components/fields/fields.component';
import {SettingsComponent} from './components/settings/settings.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {AuditsComponent} from './components/audits/audits.component';
import {HistoryComponent} from './components/history/history.component';
import {CropsComponent} from './components/crops/crops.component';
import {ScheduleComponent} from './components/schedule/schedule.component';
import {LogsComponent} from './components/logs/logs.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {LoadingComponent} from './components/shared/loading/loading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TableComponent} from './components/shared/table/table.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {
  NotificationDialogComponent
} from './components/notifications/notification-dialog/notification-dialog.component';
import {
  CreateNotificationComponent
} from './components/notifications/create-notification/create-notification.component';
import {QuillModule} from 'ngx-quill';
import {SearchPipe} from './search.pipe';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import { ActionsDialogComponent } from './components/shared/actions-dialog/actions-dialog.component';
import { SingleFieldComponent } from './components/fields/single-field/single-field.component';
import { CropSquaresComponent } from './components/crops/crop-squares/crop-squares.component';
import { CropFieldsComponent } from './components/crops/crop-fields/crop-fields.component';
import { CropSingleFieldComponent } from './components/crops/crop-single-field/crop-single-field.component';
import { UnionSplitDialogComponent } from './components/crops/union-split-dialog/union-split-dialog.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { SelectionTableComponent } from './components/shared/selection-table/selection-table.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import { FieldActionsDialogComponent } from './components/fields/single-field/field-actions-dialog/field-actions-dialog.component';
import { HelpComponent } from './components/help/help.component';
import { DisabledConfigurationDialogComponent } from './components/disabled-configuration-dialog/disabled-configuration-dialog.component';
import { NotificationsResendComponent } from './components/notifications/notifications-resend/notifications-resend.component';
import {HistorySectorComponent} from './components/history-sector/history-sector.component';
import { HistorySquareComponent } from './components/history-square/history-square.component';
import { HistoryFieldComponent } from './components/history-field/history-field.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,
    NavBarComponent,
    UsersComponent,
    ConfirmOptionDialogComponent,
    UserCreationDialogComponent,
    PersonRegistryComponent,
    CreatePersonComponent,
    EditPersonComponent,
    PersonFormComponent,
    UserEditDialogComponent,
    EditUserComponent,
    CreateUserComponent,
    SectorsComponent,
    SquaresComponent,
    FieldsComponent,
    SettingsComponent,
    NotificationsComponent,
    AuditsComponent,
    HistoryComponent,
    CropsComponent,
    ScheduleComponent,
    LogsComponent,
    LoadingComponent,
    TableComponent,
    NotificationDialogComponent,
    CreateNotificationComponent,
    SearchPipe,
    ActionsDialogComponent,
    SingleFieldComponent,
    CropSquaresComponent,
    CropFieldsComponent,
    CropSingleFieldComponent,
    UnionSplitDialogComponent,
    SelectionTableComponent,
    FieldActionsDialogComponent,
    HelpComponent,
    DisabledConfigurationDialogComponent,
    NotificationsResendComponent,
    DisabledConfigurationDialogComponent,
    HistorySectorComponent,
    HistorySquareComponent,
    HistoryFieldComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    FlexModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatTooltipModule,
    MatSidenavModule,
    HttpClientModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    NgxChartsModule,
    MatProgressSpinnerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    QuillModule.forRoot({
      modules: {
        clipboard: {
          matchVisual: true
        },
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{header: 1}, {header: 2}],               // custom button values
          [{list: 'ordered'}, {list: 'bullet'}],
          [{script: 'sub'}, {script: 'super'}],      // superscript/subscript
          [{indent: '-1'}, {indent: '+1'}],          // outdent/indent
          [{direction: 'rtl'}],                         // text direction

          [{size: ['small', false, 'large', 'huge']}],  // custom dropdown
          [{header: [1, 2, 3, 4, 5, 6, false]}],

          [{color: []}, {background: []}],          // dropdown with defaults from theme
          [{font: []}],
          [{align: []}],

          ['clean'],                                         // remove formatting button

          ['link', 'image', 'video']                         // link and image, video
        ]
      }
    }),
    MatDatepickerModule,
    MatTabsModule,
    ScrollingModule,
    MatProgressBarModule,
    MatChipsModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'el-GR'},
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}
