import {Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-history-square',
  templateUrl: './history-square.component.html',
  styleUrls: ['./history-square.component.scss']
})
export class HistorySquareComponent implements OnInit, OnChanges{
  @ViewChild('table', {static: false}) table: any;
  @ViewChild('table1', {static: true}) table1: any;
  id:number;
  name:string;
  selectedDate: string;
  hasSelectDate: boolean;
  formDate : any;
  model= 'history/squareid'
  model1='fields-all';
  firstTableHeight : number;
  secondTableHeight : number;
  displayColumns1=[{key: 'displayName', label: 'Όνομα'},
    {key: 'crop_name', label: 'Καλλιέργεια'},
    {key: 'acres', label: 'Έκταση'},
    {key: 'irrigator_fullname', label: 'Αρδευτής'},]
  displayColumns = [{key:'planned_liters',label:'Εγκριση'},{key:'total_duration',label:'Σύνολο ωρών'},{key:'total_consumption',label:'Συνολική κατανάλωση'},{key:'start_time',label:'Ώρα έναρξης'},{key:'end_time',label:'Ώρα λήξης'}];
  hasNavigation = true;
  navigateTo = 'start/history-field';
  tableTitle : string ;
  constructor(private route: ActivatedRoute) {
    this.hasSelectDate = false ;
    this.firstTableHeight = window.innerHeight - 300;
    this.secondTableHeight = 100;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.id = params['square_id'];
      this.name = params['square_name'];
      this.tableTitle = `Αγροτεμάχια τετραγώνου ${this.name}`
    })
  }
  ngOnChanges() {
    this.hasSelectDate= false;
  }

  search() {
    try{
      const filterFrom = moment(this.formDate).format('YYYY-MM-DD');
      this.selectedDate = filterFrom;
      this.hasSelectDate = true;

    }
    catch (e){
      console.log(e);
    }
  }

  isButtonDisabled(): boolean {
    if (!this.table) {
      return true;
    } else {
      return (this.table.dataSource.filteredData[0]?.date===undefined);
    }
  }
  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }
}
