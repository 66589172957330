import {Injectable} from '@angular/core';
import {CommonService} from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class SectorService {
  private date: string;
  private id: number;
  constructor(private commonService: CommonService) { }

  async getSectors() {
    return await this.commonService.getRequest(`sectors`);
  }
  async fetchSectorHistory(id:number,date: string){

    try{
      return await this.commonService.getRequest('sectors/history?id='+ id + '&date=' + date );
    }
    catch (e){
      throw e;
    }
  }
}
