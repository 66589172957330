<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="100" fxLayout="row">
      <div fxFlex="92" fxLayoutAlign="start center" fxLayoutGap="20px">
        <h1>Ιστορικό τομέα {{this.id}}</h1>
        <mat-form-field appearance="outline" class="auditDateTime">
          <input matInput [ngxMatDatetimePicker]="picker" placeholder="Επιλέξτε ημερομηνία" [(ngModel)]="formDate"
                 readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker [showSpinners]="true" [touchUi]="true" [enableMeridian]="false" hideTime>
          </ngx-mat-datetime-picker>

        </mat-form-field>
        <button mat-icon-button (click)="search()">
          <mat-icon>done</mat-icon>
        </button>
      </div>
      <div fxLayoutAlign="center center">
        <button [disabled]="isButtonDisabled()" style="justify-content: flex-end;" class="app-button" mat-icon-button color="primary" (click)="exportToExcel('history-sector')" matTooltip="Εξαγωγή σε excel">
          <mat-icon>file_download</mat-icon>
        </button>

      </div>
    </div>

  </div>
  <mat-divider></mat-divider>

  <div fxLayout="column">

    <div *ngIf="hasSelectDate" [style.height.px]="secondTableHeight" style="position: relative;">
      <app-table #table [columns]="displayColumns" [model]="model" [date]="selectedDate" [param]="this.id"></app-table>
    </div>

    <div [ngStyle]="{'margin-top': hasSelectDate ? '150px' : '0'}" style="margin-top: 155px; position: relative;">
      <app-table #table1 [title]="tableTitle" [columns]="displayColumns1" [param]="this.id" [model]="model1" [hasNavigation]="hasNavigation" [navigateTo]="navigateTo" [isDoubleTable]="true"></app-table>
    </div>

  </div>
</div>
