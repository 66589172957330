<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="95" style="display: flex; flex-direction: row;">
      <h1 style="width: 100%">Ιστορικό αγροτεμαχίου {{this.name}}</h1>
      <div style="display: flex; justify-content: flex-end">
        <button [disabled]="isButtonDisabled()" class="app-button" mat-icon-button color="primary" (click)="exportToExcel('history-field')" matTooltip="Εξαγωγή σε excel">
          <mat-icon>file_download</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <mat-divider></mat-divider>

  <div fxFlex="100">
    <div>
      <app-table #table [columns]="displayColumns" [model]="model" [param]="this.id" ></app-table>
    </div>
  </div>
</div>
