import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment/moment";

@Component({
  selector: 'app-history-sector',
  templateUrl: './history-sector.component.html',
  styleUrls: ['./history-sector.component.scss']
})
export class HistorySectorComponent implements OnInit{
  @ViewChild('table', {static: false}) table: any;
  @ViewChild('table1', {static: true}) table1: any;
  displayColumns = [{key:'planned_liters',label:'Έγκριση'},{key:'total_duration',label:'Σύνολο ωρών'},{key:'total_consumption',label:'Συνολική κατανάλωση'},{key:'start_time',label:'Ώρα έναρξης'},{key:'end_time',label:'Ώρα λήξης'}];
  model='history/sectorid';
  displayColumns1 = [{key:'name',label:'Όνομα τετραγώνου'}]
  model1 = 'squares'
  id:number;
  selectedDate: string;
  hasSelectDate: boolean;
  formDate : any;
  hasNavigation = true;
  navigateTo = 'start/history-square';
  firstTableHeight: number;
  secondTableHeight: number;
  tableTitle: string;
  constructor(private route:ActivatedRoute) {
    this.hasSelectDate = false;
    this.firstTableHeight = window.innerHeight - 300;
    this.secondTableHeight = 100;
    this.hasSelectDate = false;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.id = params['sector_id'];
      this.tableTitle = `Τετράγωνα τομέα ${this.id}`;
    })
  }

  search() {
    try{
      const filterFrom = moment(this.formDate).format('YYYY-MM-DD');
      this.selectedDate = filterFrom;
        this.hasSelectDate = true;
    }
    catch (e){
      console.log(e);
    }
  }
  isButtonDisabled(): boolean {
    if (!this.table) {
      return true;
    } else {
      return (this.table.dataSource.filteredData[0]?.date===undefined);
    }
  }



  exportToExcel(fileName: string) {
    if(this.table) {
      this.table.exportToExcel(fileName);
    }
  }
}
