<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="95" fxLayoutGap="15px" fxLayoutAlign="start center">
      <h1>Στατιστικά</h1>
      <mat-form-field appearance="outline" >
        <mat-select [(ngModel)]="selectedCategory" placeholder="Επιλέξτε κατηγορία"
                    (selectionChange)="categoryChanged()" fxFlex="100">
          <mat-option *ngFor="let category of categories" [value]="category">{{category.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="auditDateTime" *ngIf="selectedCategory?.id === 3">
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Ημερομηνία από" [(ngModel)]="fromDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true"
                                 [touchUi]="true" [enableMeridian]="false"
                                 hideTime>
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="auditDateTime" *ngIf="selectedCategory?.id === 3">
        <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Ημερομηνία έως" [(ngModel)]="toDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker1 [showSpinners]="true" [showSeconds]="false"
                                 [touchUi]="true" [enableMeridian]="false"
                                 [hideTime]="true">
        </ngx-mat-datetime-picker>
      </mat-form-field>

    <button mat-icon-button (click)="search()">
      <mat-icon>done</mat-icon>
    </button>
    </div>
    <button [disabled]="isButtonDisabled()" mat-icon-button color="primary" matTooltip="Εξαγωγή σε excel" (click)="exportToExcel(selectedCategory.id)">
      <mat-icon>file_download</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
  <div fxLayout="row" fxFlex="100">

    <ngx-charts-bar-horizontal-2d
      barPadding="0.2"
      [style.height.px]="800"
      [results]="data"
      [xAxisLabel]="chartOptions.xLabel"
      [legendTitle]="' '"
      [yAxisLabel]="chartOptions.yLabel"
      [legend]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="false"></ngx-charts-bar-horizontal-2d>
    <!--  <ngx-charts-bar-vertical-->
    <!--    [style.height.px]="800"-->
    <!--  [results]="data"-->
    <!--  [xAxisLabel]="'Τομείς'"-->
    <!--  [legendTitle]="'Τομείς'"-->
    <!--  [yAxisLabel]="'Sale'"-->
    <!--  [legend]="true"-->
    <!--  [showXAxisLabel]="true"-->
    <!--  [showYAxisLabel]="true"-->
    <!--  [xAxis]="true"-->
    <!--  [yAxis]="true"-->
    <!--  [gradient]="true">-->
    <!--</ngx-charts-bar-vertical>-->
  </div>
</div>
