<div fxLayout="column" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxFlex="95">
      <h1>Ιστορικό</h1>
    </div>

  </div>
  <mat-divider></mat-divider>

  <div fxFlex="100">
    <app-table #table [columns]="displayColumns" [model]="model" [hasNavigation]="hasNavigation" [navigateTo]="navigateTo"></app-table>
  </div>
</div>
