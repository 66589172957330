import { Component, OnInit, Inject } from '@angular/core';
import {UserService} from "../../../../services/user/user.service";
import {NotificationsService} from "../../../../services/notifications.service";
import {ToastMessagesService} from "../../../../services/toast-messages.service";
import * as moment from "moment/moment";
import {MatDialogRef} from "@angular/material/dialog";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-notifications-resend',
  templateUrl: './notifications-resend.component.html',
  styleUrls: ['./notifications-resend.component.scss']
})
export class NotificationsResendComponent implements OnInit {
  users:any[]=[];
  selectedUsers: any[]=[];
  searchTxt: string;
  isLoading: boolean;
  constructor( public dialogRef: MatDialogRef<NotificationsResendComponent>,
               private userService: UserService,
               private notificationService: NotificationsService,
               public toastMessagesService: ToastMessagesService,
              @Inject(MAT_DIALOG_DATA) public data:any
               ) { }

  async ngOnInit(): Promise<void> {
    try {
      this.users = await this.initUsers();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    }
  }


  async initUsers() {
    return await this.userService.getUsers();
  }


  async submit() {
    const objectToSend = {
      id: this.data.id,
      title: this.data.title,
      text: this.data.text,
      users: this.selectedUsers.map(user => user.id),
      sendDate: moment(new Date()).format('YYYY-MM-DD HH:mm')
    }
    try {
      this.isLoading = true;
      await this.notificationService.resend(objectToSend);
      this.toastMessagesService.toastMessages('Η ειδοποίηση στάλθηκε ξανά επιτυχώς');
      this.dialogRef.close();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage(e.message);
    } finally {
      this.isLoading = false;
    }
  }
}
