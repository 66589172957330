import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-history-field',
  templateUrl: './history-field.component.html',
  styleUrls: ['./history-field.component.scss']
})
export class HistoryFieldComponent implements OnInit {
  @ViewChild('table', {static: true}) table: any;
  id: number;
  name: string;
  model = 'history/web/fieldid';
  displayColumns = [{key:'irrigation_method_name',label:'Τρόπος Άρδευσης'},{key:'start_time',label:'Ώρα έναρξης'},{key:'end_time',label:'Ώρα λήξης'},{key:'duration',label:'Διάρκεια'},{key:'consumption',label:'Κατανάλωση'}];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['field_id'];
      this.name = params['field_name'];
    });
  }
  isButtonDisabled() {
    return this.table.dataSource.filteredData.length === 0;
  }
  exportToExcel(fileName: string) {
    this.table.exportToExcel(fileName);
  }

}
